<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col class="pa-0">
				<div class="deep-purple--text text-h6 text-decoration-underline">Présentation</div>
				<div class="text-justify">
					Le site SP Planning est une application web destinée
					 à la gestion courante des sapeurs-pompiers 
					 volontaires en centre de secours (planification des gardes, 
					 suivi des FMPA, présence aux manœuvres…). Son accès n’est pas 
					 publique et nécessite la création préalable d’un compte utilisateur 
					 par un administrateur du centre de secours.
				</div>
				<div class="deep-purple--text text-h6 text-decoration-underline mt-4">Editeur</div>
				<div>Le site SP Planning est créé et édité par : </div>
				<div class="ml-12 mt-2">Guillaume HUET</div>
				<div class="ml-12">3 cour de l’oratoire</div>
				<div class="ml-12">18000 BOURGES</div>
				<div class="ml-12"><v-icon class="mr-2" size="20">mdi-phone-outline</v-icon>06 81 54 66 77</div>
				<div class="ml-12"><v-icon class="mr-2" size="20">mdi-email-outline</v-icon>spplanning18@gmail.com</div>

				<div class="deep-purple--text text-h6 text-decoration-underline mt-4">Hébergement</div>
				<div class="text-justify">
					Le site SP Planning est hébergé chez OVH sur un serveur privé.
				</div>
				<div class="deep-purple--text text-h6 text-decoration-underline mt-4">Utilisation des cookies</div>
				<div class="text-justify">
					Un cookie est un fichier informatique permettant d'analyser le comportement des usagers lors 
					de la visite d'un site internet ou de l'utilisation d'un logiciel ou d'une 
					application mobile.
				</div>
				<div class="text-justify mt-2">Le site SP Planning n’utilise pas de cookies, autre que les cookies "techniques" liés au bon fonctionnement de l'application.</div>
			
				<div class="deep-purple--text text-h6 text-decoration-underline mt-4">Utilisation des données personnelles et confidentialité</div>
				<div class="text-justify">
					L’accès au site SP Planning n’est réservé qu’aux seuls membres d’un centre 
					de secours pour lesquels un compte aura été créé au préalable par un administrateur.
				</div>
				<div class="text-justify mt-2">
					Ce compte est créé avec les données personnelles en possession de l’administrateur : matricule, 
					nom et prénom.
				</div>
				<div class="text-justify mt-2">
					L’utilisateur peut ensuite, s’il le souhaite, ajouter à son compte les données personnelles suivantes :
				</div>
				<ul class="ml-12 mt-2">
					<li>adresse email</li>
					<li>numéro de téléphone</li>
				</ul>
				<div class="text-justify mt-2">
					Ces données sont destinées à :
				</div>
				<ul class="ml-12 mt-2">
					<li>alimenter l’annuaire du centre de secours. L’utilisateur à toutefois la possibilité, via la page de gestion 
						de son compte, de ne pas faire apparaître ces données dans l’annuaire.</li>
					<li>permettre l’envoi de notifications par email à l’utilisateur (rappels, diffusion de planning…). 
						L’utilisateur à également la possibilité, via la page de gestion de son compte, de demander à ne pas recevoir d’email.</li>
				</ul>
				<div class="text-justify mt-2">
					A chaque instant, l’utilisateur à la possibilité d’ajouter, modifier ou supprimer ces données. Aucune sauvegarde 
					n’est effectuée en cas de suppression.
				</div>

				<div class="text-justify mt-2">
					Aucune donnée n'est utilisée à des fins commerciales ou communiquée à un tiers.
				</div>

				<div class="text-justify mt-2">
					Pour toute question relative à la protection des données personnelles du site, merci de 
					contacter Guillaume Huet (spplanning18@gmail.com).
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
    import axios from 'axios'
    export default {
        created() {
			this.$store.commit('majTitrePage', 'Mentions légales')
        },
        mounted() {
			
        },
        data() {
			return {
				
			}
		},
		computed: {
			
  
		},
		methods: {
			
        },
  	}
</script>

<style>




</style>